import { Card } from 'react-bootstrap';
import { H3, P, H4 } from '@collabodoc/component-library';
import styled from 'styled-components';

const CertificateCard = styled(Card)`
border:none;
border-radius: 0px;
`;

const CardText = styled.p`
margin:0;
white-space: pre-wrap; 
`;

const CardHeaderText = styled(H3)`
margin-left: -5px;
display: inline;
font-weight: ${({ theme }) => theme.card.cardHeader.fontWeight};
color: ${({ theme }) => theme.card.cardHeader.color};
`;

const SectionHeader = styled(H4)`
color: ${({ theme }) => theme.sectionHeader.color};
`;

const CardHeaderInfo = styled(P)`
margin-left: 5px;
display: inline;
`;

const CardHeader = styled(Card.Header)`
padding: 7px 15px 5px;
border-top: none;
background-color:#e4e4e4;
:first-child{
    border-radius: 0px;
}
`;

const CardBody = styled(Card.Body)`
padding: 15px;
border: none;
`;

const Asterisk = styled.span`
color: ${({ theme }) => theme.colors.danger};
font-weight: bold;
margin-right: 5px;
`;

export { CardText, CardHeaderText, CardHeader, CardBody, CardHeaderInfo, Asterisk, CertificateCard, SectionHeader }