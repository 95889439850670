import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import {reverse} from "named-urls";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled, {useTheme} from "styled-components";
import "tippy.js/dist/tippy.css";
import {SickLeaveType} from "../enums/Enums";
import {SITE_URLS} from "../enums/urls";
import CommentModal from "./CommentModal";
import DropDownMenu from "./DropDown";

const PatientTableRow = ({rowData}) => {
  const history = useHistory();
  const theme = useTheme();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState(rowData.comment);
  const [dropdownOpen, setDropDownOpen] = useState(false);

  const handleRowClick = (e) => {
    e.preventDefault();
    if (rowData.sickLeaveType === SickLeaveType.SHORT) {
      history.push(
          reverse(SITE_URLS.EMPLOYERSCERTIFICATEFORM, {
            sickLeaveFormId: rowData.sickLeaveFormId,
          })
      );
    } else {
      history.push(
          reverse(SITE_URLS.SICKLEAVEFORM, {
            sickLeaveFormId: rowData.sickLeaveFormId,
          })
      );
    }
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (!showCommentModal) {
      setShowCommentModal(!showCommentModal);
    }
  };

  let sickLeaveLength;
  if (rowData.sickLeaveType === SickLeaveType.SHORT) {
    sickLeaveLength = "14 dagar eller kortare";
  } else {
    sickLeaveLength = "15 dagar eller mer";
  }

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDatetime = Intl.DateTimeFormat("sv-SE", options).format(
      new Date(rowData.timeOfRegistration)
  );

  return (
      <TableRow key={rowData.sickLeaveFormId} onClick={handleRowClick}>
        <TableData onClick={(e) => e.stopPropagation()}>
          <DropDownMenu
              rowData={rowData}
              showMenu={dropdownOpen}
              toggleMenu={() => setDropDownOpen(!dropdownOpen)}
              setOpen={setDropDownOpen}
              handleRowClick={handleRowClick}
          />
        </TableData>
        <TableData data-title="PersonId">{rowData.personId}</TableData>
        <TableData data-tile="FirstName">{rowData.firstName}</TableData>
        <TableData data-title="LastName">{rowData.lastName}</TableData>
        <TableData data-title="SickLeaveType">{rowData.isNewSickLeave ? "Ny" : "Förlängning"}</TableData>
        <TableData data-title="SickleaveLength">{sickLeaveLength}</TableData>
        <TableData data-title="TimeOfRegistration">{formattedDatetime}</TableData>
        {rowData.comment ? (
            <StyledTippy
                $customColor={theme.tippyTheme.backgroundColor}
                theme="tippy"
                content={rowData.comment}
                placement="left"
                animation="scale-subtle"
                duration={0}
            >
              <TableDataWithOverFlow onClick={handleInputClick}>
                <FontAwesomeIcon className="" icon={faPencilAlt}/>{" "}
                {rowData.comment}
                <CommentModal
                    sickLeaveFormId={rowData.sickLeaveFormId}
                    currentComment={rowData.comment}
                    toggleCommentModal={() => setShowCommentModal(!showCommentModal)}
                    showCommentModal={showCommentModal}
                    hideCommentModal={() => setShowCommentModal(false)}
                    changeComment={setComment}
                />
              </TableDataWithOverFlow>
            </StyledTippy>
        ) : (
            <TableData width="350px" onClick={handleInputClick}>
              <FontAwesomeIcon icon={faPencilAlt}/> {comment}
              <CommentModal
                  sickLeaveFormId={rowData.sickLeaveFormId}
                  currentComment={comment}
                  toggleCommentModal={() => setShowCommentModal(!showCommentModal)}
                  showCommentModal={showCommentModal}
                  hideCommentModal={() => setShowCommentModal(false)}
                  changeComment={setComment}
              />
            </TableData>
        )}
      </TableRow>
  );
};

const TableRow = styled.tr`
  cursor: pointer;
  color: ${({theme}) => theme.patientList.color};

  :nth-child(odd) {
    background-color: ${({theme}) =>
            theme.patientList.oddRow.backgroundColor};
  }

  :nth-child(even) {
    background-color: ${({theme}) =>
            theme.patientList.evenRow.backgroundColor};
  }

  :hover {
    background-color: ${({theme}) =>
            theme.patientList.row.hover.backgroundColor};
    color: ${({theme}) => theme.patientList.row.hover.color};
  }
`;

const TableData = styled.td`
  padding: 5px;
`;

const TableDataWithOverFlow = styled(TableData)`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


const StyledTippy = styled(Tippy)`
  background-color: ${(props) => props.$customColor};

  &.tippy-box[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: ${(props) => props.$customColor};
  }
`;

export default PatientTableRow;
