

const EmployersCertificateColumn =
{
    OTHER_PATIENT_INFO: "OtherPatientInfo",
    PATIENT_ABILITY_DESCRIPTION: "PatientAbilityDescription",
    PATIENT_DISABILITY_DESCRIPTION: "PatientAbilityDescription",
    PATIENT_DISABILITY_WHY_DESCRIPTION: "PatientDisabilityWhyDescription",
    PATIENT_TAXI: "PatientTaxi",
    PATIENT_WORKPLACE_ADAPTATIONS: "PatientWorkplaceAdaptations",
    DOCTOR_DISABILITY_ASSESSMENT: "DoctorDisabilityAssessment",
    SICKLEAVE_START: "SickLeaveStart",
    SICKLEAVE_END: "SickLeaveEnd",
    SICKLEAVE_EXTENT: "SickLeaveExtent"
}

const OccupationEnum =
{
    EMPLOYED: "Employed",
    UNEDMPLOYED: "Unemployed",
    PARENTAL_LEAVE: "ParentalLeave",
    STUDENT: "Student"
}

const SickLeaveType = {
    LONG: "Long",
    SHORT: "Short",
    UNDECIDED: "Undecided"
}

const PatientListStatusEnum = {
    REGISTERED: "Registered",
    TRANSFERRED: "Transfered"
}

const AnonymizationConstants = {
    SickLeaveMaxNumberOfDaysInRegistered: 21,
    SickLeaveMaxNumberOfDaysInTransferred: 7
}

export {EmployersCertificateColumn, OccupationEnum, SickLeaveType, PatientListStatusEnum, AnonymizationConstants}
