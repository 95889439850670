import {pdf} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import React from "react";
import {getTheme} from "../../Content/themes/getTheme";
import {OccupationEnum} from "../../enums/Enums";
import {PrintableEmployersCertificate} from "../PrintableEmployersCertificate";

const theme = getTheme();
const generatePdfDocument = async (
    employersCertificateForm,
    oidcUser,
    patientAbilityRef,
    patientDisabilityRef,
    patientDisabilityWhyRef,
    workplaceAdaptationsRef,
    taxiRef,
  assessmentRef,
  diagnosisSectionModel,
  sickLeavePeriods
) => {
  const documentData = getPDFData(
    employersCertificateForm,
    oidcUser,
    patientAbilityRef,
    patientDisabilityRef,
    patientDisabilityWhyRef,
    workplaceAdaptationsRef,
    taxiRef,
    assessmentRef,
    diagnosisSectionModel,
    sickLeavePeriods
  );
  const blob = await pdf(
    <PrintableEmployersCertificate theme={theme} document={documentData} />
  ).toBlob();
  saveAs(blob, `Sjukintyg_${employersCertificateForm.patientName}`);
};

const getPDFData = (
  employersCertificateForm,
  oidcUser,
  patientAbilityRef,
  patientDisabilityRef,
  patientDisabilityWhyRef,
  workplaceAdaptationsRef,
  taxiRef,
  assessmentRef,
  diagnosisSectionModel,
  sickLeavePeriods
) => {
  return {
    occupation:
    employersCertificateForm.occupation,
    occupationEstablishment: getOccupationEstablishment(
        employersCertificateForm.occupation
    ),
    patientName: employersCertificateForm.patientName,
    personId: employersCertificateForm.personId,
    patientAbilityDescription: patientAbilityRef.current.value,
    patientDisabilityDescription: patientDisabilityRef.current.value,
    patientDisabilityWhyDescription: patientDisabilityWhyRef.current.value,
    patientTaxi: taxiRef.current.value,
    patientWorkplaceAdaptations: workplaceAdaptationsRef.current.value,
    doctorDisabilityAssessment: assessmentRef.current.value,
    diagnoses:
        diagnosisSectionModel && getDiagnosesString(diagnosisSectionModel),
    sickLeavePeriods:
        sickLeavePeriods && getSickLeavePeriodsString(sickLeavePeriods),
    employer: employersCertificateForm.employer,
    userName: `${oidcUser.profile.firstname} ${oidcUser.profile.lastname}`,
    userRole: `${getUserRoleString(oidcUser.profile.role)}`,
    careCenterName: employersCertificateForm.careGiverInfo.careCenterName,
    careCenterAddress: employersCertificateForm.careGiverInfo.careCenterAddress,
    careCenterPhone: employersCertificateForm.careGiverInfo.careCenterPhone,
    today: new Date().toLocaleDateString("sv"),
  };
};

const getDiagnosesString = (diagnosisSectionModel) => {
  return diagnosisSectionModel.diagnoseItems
    .map((diagnosis) => `${diagnosis.code} ${diagnosis.text}`)
    .join(" \n");
};

const getSickLeavePeriodsString = (sickLeavePeriods) => {
  const sortedPeriods = sortPeriods(sickLeavePeriods);
  const newLine = "\n";
  return sortedPeriods
    .filter(
      (period) =>
        period.sickLeaveStart && period.sickLeaveEnd && period.sickLeaveExtent
    )
    .map((period) => {
      let start = new Date(period.sickLeaveStart).toLocaleDateString("sv");
      let end = new Date(period.sickLeaveEnd).toLocaleDateString("sv");
      return `Fr.o.m. ${start} t.o.m. ${end} med ${period.sickLeaveExtent}% omfattning ${newLine}`;
    });
};

const CLINIC_ROLES = {
  DOCTOR: "DoctorUser",
  NURSE: "NurseUser",
  SECRETARY: "SecretaryUser",
  PHYSIOTHERAPIST: "PhysiotherapistUser",
  PSYCHOLOGIST: "PsychologistUser",
  COUNSELOR: "CounselorUser",
  OCCUPATIONAL_THERAPIST: "OccupationalTherapistUser",
};

const translations = {
  [CLINIC_ROLES.DOCTOR]: "Läkare",
  [CLINIC_ROLES.NURSE]: "Sjuksköterska",
  [CLINIC_ROLES.SECRETARY]: "Sekreterare",
  [CLINIC_ROLES.PHYSIOTHERAPIST]: "Fysioterapeut",
  [CLINIC_ROLES.PSYCHOLOGIST]: "Psykolog",
  [CLINIC_ROLES.COUNSELOR]: "Kurator",
  [CLINIC_ROLES.OCCUPATIONAL_THERAPIST]: "Arbetsterapeut",
};

const getUserRoleString = (role) => {
  let clinicalRole;
  if (Array.isArray(role)) {
    clinicalRole = role.find((r) => Object.values(CLINIC_ROLES).includes(r));
  } else {
    clinicalRole = Object.values(CLINIC_ROLES).includes(role) && role;
  }
  return clinicalRole ? translations[clinicalRole] : "";
};

const getOccupationEstablishment = (occupation) => {
  switch (occupation) {
    case OccupationEnum.EMPLOYED:
      return "arbetsgivaren";
    case OccupationEnum.UNEDMPLOYED:
      return "Arbetsförmedlingen";
    case OccupationEnum.PARENTAL_LEAVE:
      return "arbetsgivaren";
    case OccupationEnum.STUDENT:
      return "lärosätet";
    default:
      return "arbetsgivaren";
  }
};

const sortPeriods = (sickLeavePeriods) => {
  return sickLeavePeriods.sort(function (a, b) {
    a = a.sickLeaveStart && new Date(a.sickLeaveStart);
    b = b.sickLeaveStart && new Date(b.sickLeaveStart);
    let index;
    if (!a) {
      index = 1;
    } else if (!b) {
      index = -1;
    } else {
      index = a > b ? 1 : a < b ? -1 : 0;
    }
    return index;
  });
};

export { generatePdfDocument, getOccupationEstablishment };
