import React, { useContext, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Row } from 'react-bootstrap';
import { CareCenterContext, useDelete, useGet, usePost } from '@collabodoc/component-library';
import { API_URLS } from '../enums/urls';
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { SectionHeader } from './StyledComponents/CardStyles';

const DiagnosisSection = ({ onDiagnosisAdd, onDiagnosisDelete, symptomFormId, model }) => {

    const { currentCareCenterId } = useContext(CareCenterContext);
    const { oidcUser } = useReactOidc();
    const typeahead = useRef();
    const { response: diagnoses = [], isLoading } = useGet(API_URLS.DIAGNOSES(parseInt(currentCareCenterId)), oidcUser.access_token)
    const { doPost: addDiagnosis } = usePost(API_URLS.DIAGNOSIS_ADD, oidcUser.access_token);
    const { doDelete: deleteDiagnosis } = useDelete("", oidcUser.access_token);

    const handleDeleteDiagnosis = (event, diagnosisId) => {
        event.preventDefault();
        deleteDiagnosis(API_URLS.DIAGNOSIS_DELETE(symptomFormId, diagnosisId))
            .then(() => onDiagnosisDelete(diagnosisId));
    }

    const handleAddDiagnosis = (diagnosis) => {
        if (diagnosis) {
            addDiagnosis({
                SymptomFormId: symptomFormId,
                DiagnosisTemplateId: diagnosis.id
            })
                .then(() => pushDiagnosis(diagnosis));
        }
    }

    const pushDiagnosis = (diagnosis) => {
        typeahead.current.getInstance().clear();
        onDiagnosisAdd(diagnosis);
    }

    return (
        <>
            <SectionHeader>Diagnos</SectionHeader>
            {isLoading && <Spinner icon={faSpinner} />}
            <StyledTypeahead
                ref={typeahead}
                options={diagnoses}
                id="diagnosisTypeahead"
                labelKey={(option) => `${option.code} ${option.text}`}
                placeholder="Sök diagnos"
                filterBy={['text', 'code', 'alt']}
                ignoreDiacritics={false}
                emptyLabel="Inga diagnoser matchar sökvärdet"
                highlightOnlyResult={true}
                flip={true}
                maxResults={50}
                paginationText="Visa fler..."
                selectHintOnEnter={true}
                minLength={1}
                disabled={diagnoses.length === 0}
                onChange={
                    diagnosis => handleAddDiagnosis(diagnosis[0])
                }
                renderMenuItemChildren={({code, text, alt}) =>
                    <span><b>{code}</b>, {text} {alt && <i>({alt})</i>}</span>
                }
            />
            <DiagnoseSection>
                {model.diagnoseItems && model.diagnoseItems.map((diagnosis, index) => (
                    <Row key={index} >
                        <Diagnosis>
                            <>
                                <DiagnosisInfo><b id={diagnosis.code}>{diagnosis.code}</b> {diagnosis.text} </DiagnosisInfo>
                                <Icon icon={faTrash} onClick={(e) => handleDeleteDiagnosis(e, diagnosis.id)} />
                            </>
                        </Diagnosis>
                    </Row>
                ))}

            </DiagnoseSection>
        </>
    );
}

const Spinner = styled(FontAwesomeIcon)`
    position: absolute;
    z-index: 2;
    margin-top: 6px;
    font-size: 25px;
    right: 25px;
    color: ${({ theme }) => theme.colors.primary};
    animation: spin 2s linear infinite;

    @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
`;

const StyledTypeahead = styled(Typeahead)`
    .form-control {
        position: relative;
        border-radius: 0px;
        background-color: ${({ theme }) => theme.typeahead.backgroundColor};
        border: 1px solid  ${({ theme }) => theme.typeahead.borderColor};
        color: ${({ theme }) => theme.typeahead.color};
        :focus{
            border: 1px solid  ${({ theme }) => theme.colors.primary};
            box-shadow: none;
        }
    }
`;

const DiagnoseSection = styled.div`
    padding-top: 15px;
`

const Diagnosis = styled(Col)`
    margin: 0px 15px 3px 15px;
    background-color: #f1f1f1;
    padding-left: 5px;
`;

const DiagnosisInfo = styled.span`
float:left
margin-right: 5px;
`;

const Icon = styled(FontAwesomeIcon)`
cursor: pointer;
color: ${({ theme }) => theme.colors.primary};
float:right;
margin-top:4px;
`;

export default DiagnosisSection;
