import { useReactOidc } from "@axa-fr/react-oidc-context";
import { PrimaryButton, useGet, usePost } from "@collabodoc/component-library";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API_URLS } from "../../enums/urls";
import SickLeaveTableRow from "./SickLeaveTableRow";

const SickLeavePeriodTable = ({
  sickLeaveFormId,
  updateSickLeavePeriods,
  sickLeavePeriods,
}) => {
  const moment = extendMoment(Moment);
  const { oidcUser } = useReactOidc();
  const [overlapError, setOverlapError] = useState(false);
  const {
    doGet: reFetchSickLeavePeriods,
    response: sickLeavePeriodModel,
    isLoading,
  } = useGet(
    API_URLS.EMPLOYERSCERTIFICATEFORM_GETSICKLEAVEPERIODS(sickLeaveFormId),
    oidcUser.access_token
  );
  const { doPost: addSickLeavePeriod } = usePost(
    API_URLS.EMPLOYERSCERTIFICATEFORM_ADDSICKLEAVEPERIOD(sickLeaveFormId),
    oidcUser.access_token
  );

  useEffect(() => {
    updateSickLeavePeriods(sickLeavePeriodModel);
  }, [updateSickLeavePeriods, sickLeavePeriodModel]);

  const addPeriod = () => {
    addSickLeavePeriod().then(() => reFetchSickLeavePeriods());
  };

  const validateRanges = (sickLeavePeriod) => {
    const index = sickLeavePeriods.findIndex(
      (period) => period.periodId === sickLeavePeriod.periodId
    );
    sickLeavePeriods[index] = sickLeavePeriod;
    const ranges = sickLeavePeriods
      .filter((period) => period.sickLeaveEnd && period.sickLeaveStart)
      .map((period) =>
        moment.range(
          new Date(new Date(period.sickLeaveStart).toDateString()),
          new Date(new Date(period.sickLeaveEnd).toDateString()),
        )
      );

    for (let i = 0; i <= ranges.length - 1; i++) {
      let range1 = ranges[i];
      const rangesToCompare = ranges.filter((r) => r !== range1);
      for (let i = 0; i <= rangesToCompare.length - 1; i++) {
        let range2 = rangesToCompare[i];

        if (range1.overlaps(range2)) {
          return false;
        }
      }
    }
    return true;
  };

  const sortPeriods = (sickLeavePeriods) => {
    return sickLeavePeriods.sort(function (a, b) {
      a = a.sickLeaveStart && new Date(a.sickLeaveStart);
      b = b.sickLeaveStart && new Date(b.sickLeaveStart);
      let index;
      if (!a) {
        index = 1;
      } else if (!b) {
        index = -1;
      } else {
        index = a > b ? 1 : a < b ? -1 : 0;
      }
      return index;
    });
  };

  const handleOverlapError = () => {
    setOverlapError(true);
    setTimeout(() => setOverlapError(false), 2000);
  };

  const sortedSickLeavePeriods = !isLoading && sortPeriods(sickLeavePeriods);

  const tableRows =
    sortedSickLeavePeriods &&
    sortedSickLeavePeriods.map((sickLeavePeriod) => (
      <SickLeaveTableRow
        key={sickLeavePeriod.periodId}
        rowData={sickLeavePeriod}
        reFetch={reFetchSickLeavePeriods}
        sickLeaveFormId={sickLeaveFormId}
        validateRanges={validateRanges}
        handleOverlapError={handleOverlapError}
      />
    ));

  return (
    <>
      {tableRows.length === 0 ? (
        <i>Inga sjukskrivningsperioder tillagda</i>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Välj datum</th>
              <th>Periodens start</th>
              <th>Periodens slut</th>
              <th>Omfattning i %</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      )}
      <AddPeriodButton onClick={addPeriod}>
        <FontAwesomeIcon icon={faPlus} /> Lägg till period
      </AddPeriodButton>
      {overlapError && (
        <ValidationError>
          Överlappande sjukskrivningsperioder. Perioden sparades inte.
        </ValidationError>
      )}
    </>
  );
};

const Table = styled.table`
  width: 100%;
  margin-bottom: 1rem;
`;

const AddPeriodButton = styled(PrimaryButton)`
  font-size: 13px;
  padding: 3px 6px 3px 6px;
  float: right;
`;

const ValidationError = styled.b`
  color: ${({ theme }) => theme.colors.danger};
  float: left;
`;

export default SickLeavePeriodTable;
