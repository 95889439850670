import { CareCenterContext } from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import {SITE_URLS} from "./enums/urls";

export const CareCenterRequirement = ({ children }) => {
    const { currentCareCenterId } = useContext(CareCenterContext);
    if (!currentCareCenterId) {
        return <Redirect to={SITE_URLS.CHOOSE_CARECENTER} />;
    }
    return children;
};