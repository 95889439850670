import React  from "react";
import { Col } from "react-bootstrap";
import { P } from "@collabodoc/component-library";
import styled from "styled-components";

const Pagination = ({
  numberOfPatients,
  rowsPerPage = 15,
  activePage = 1,
  handleClick,
}) => {
  const pageCount = Math.ceil(numberOfPatients / rowsPerPage) || 1;
  let rowsShown;
  if (activePage === pageCount) {
    rowsShown = numberOfPatients - rowsPerPage * (pageCount - 1);
  } else {
    rowsShown = rowsPerPage;
  }

  const pageNumbers = Array.from(new Array(pageCount), (_, i) => i + 1);

  return (
    pageCount > 1 && (
      <Col>
        <List>
          {pageNumbers.map((number) =>
            number === activePage ? (
              <ActiveWrapper key={number}>
                <li>{number}</li>
              </ActiveWrapper>
            ) : (
              <InactiveWrapper key={number}>
                <li
                  onClick={(e) => handleClick(e)}
                  data-page={number}
                >
                  {number}
                </li>
              </InactiveWrapper>
            )
          )}
        </List>
        <PaginationText>
          Nu visas {rowsShown} av totalt {numberOfPatients}
        </PaginationText>
      </Col>
    )
  );
};

const List = styled.ul`
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: ${({ theme }) => theme.patientList.pagination.lineHeight};
  display: inline-block;
  margin: 3px;
  cursor: pointer;
`;

const ActiveWrapper = styled(Wrapper)`
  border: 1px solid
    ${({ theme }) => theme.patientList.pagination.active.backgroundColor};
  background-color: ${({ theme }) =>
    theme.patientList.pagination.active.backgroundColor};
  color: ${({ theme }) => theme.patientList.pagination.active.color};
`;

const InactiveWrapper = styled(Wrapper)`
  border: 1px solid black;
`;

const PaginationText = styled(P)`
  text-align: center;
`;

export default Pagination;
