import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import PatientSearch from './PatientSearch';
import PatientTable from './PatientTable';
import Pagination from './Pagination';
import {
    AnonymizationInfoFooter,
    CareCenterContext,
    LoadingView,
    PrimaryButton,
    SecondaryButton,
    useGet
} from '@collabodoc/component-library';
import {API_URLS} from '../enums/urls';
import {useReactOidc} from "@axa-fr/react-oidc-context";
import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";
import styled from "styled-components";
import {AnonymizationConstants, PatientListStatusEnum} from '../enums/Enums';

const PatientList = () => {
    document.body.style.backgroundColor = "#FFFFFF";
    const {oidcUser} = useReactOidc();
    const {currentCareCenterId} = useContext(CareCenterContext);
    const rowsPerPaginatedPage = 15;
    const [patientListStatus, setPatientListStatus] = useState(PatientListStatusEnum.REGISTERED);
    const [activePage, setActivePage] = useState(1);
    let numberOfPatients = null;
    const [searchText, setSearchText] = useState(null);
    const caregivername = window.REACT_APP_CAREGIVER_SITENAME;
    const registrationUrl = `https://${caregivername}-mobile.collabodoc.com/${currentCareCenterId}/Patient/SickLeaveTermsAndConditions`

    const {
        response: patients = [],
        isLoading,
        doGet
    } = useGet(API_URLS.PATIENTS(currentCareCenterId, "sv-SE"), oidcUser.access_token);

    useEffect(() => {
        const url = window.REACT_APP_API + `/sickleavehub?careCenterId=${currentCareCenterId}`;

        const connection = new HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,                
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .configureLogging("information")
            .build();

        connection.start()
            .then(() => {
                console.debug(`Connected to SickLeaveHub`);
                connection.on('refresh', () => {

                    doGet().then(() => console.debug("Refreshed patientlist"));

                })
            })
            .catch(err => console.error(err));
        return () => {
            console.debug('Disconnecting from SickLeaveHub');
            connection.stop().then(() => console.debug('Disconnected from SickLeaveHub'))
                .catch(err => console.error(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCareCenterId]);


    const handleSearchValueChange = (event) => {
        if (event.target.value) {
            setSearchText(event.target.value.toLowerCase().split(' '));
        } else {
            setSearchText("")
        }
    }

    const multiFilter = (patients, searchWords) => {
        const columns = ['firstName', 'lastName', 'personId', 'timeOfRegistration'];
        return patients.filter((patient) => {
            return searchWords.every(searchWord => {
                    return columns.some(function (column) {
                        return patient[column].toLowerCase().includes(searchWord);
                    });
                }
            );
        });
    }

    const filterPatientsPagination = (activePage, patients) => {
        // Get current rows
        let indexOfLastRow = activePage * rowsPerPaginatedPage;
        let indexOfFirstRow = indexOfLastRow - rowsPerPaginatedPage;

        return patients.slice(indexOfFirstRow, indexOfLastRow);
    }

    const filterPatients = (patients, statusId, searchText, activePage) => {
        var filteredPatients = patients.filter(function (patient) {
            return patient.patientStatus === statusId;
        });
        if (searchText) {
            filteredPatients = multiFilter(filteredPatients, searchText);
        }
        numberOfPatients = filteredPatients.length;
        return filterPatientsPagination(activePage, filteredPatients);

    }

    const handleStatusChange = (status) => {
        // Check if pressing the "other" button, changing patientStatusId
        if (status !== patientListStatus) {
            //Empty search string if it has any text
            if (searchText != null) {
                setSearchText(null);
                document.getElementById("patientSearch").value = null;
            }
            //Resetting active page number to 1
            setActivePage(1);
            // Update filteredPatients
            setPatientListStatus(status);
        }
    };

    const handlePaginate = (e) => {
        const activePageNr = parseInt(e.target.getAttribute("data-page"));
        setActivePage(activePageNr);
    };

    const handleNewFormButtonClick = () => {
        window.open(registrationUrl, '_blank');
    }

    let filteredPatients = filterPatients(patients, patientListStatus, searchText, activePage)

    return (
        <>
            <Row>
                <Col sm={6}>
                    {patientListStatus === PatientListStatusEnum.REGISTERED ?
                        <>
                            <PatientListPrimaryButton
                                onClick={() => handleStatusChange(PatientListStatusEnum.REGISTERED)}>
                                Registrerade
                            </PatientListPrimaryButton>
                            <PatientListSecondaryButton
                                onClick={() => handleStatusChange(PatientListStatusEnum.TRANSFERRED)}>
                                Överförda
                            </PatientListSecondaryButton>
                        </>
                        :
                        <>
                            <PatientListSecondaryButton
                                onClick={() => handleStatusChange(PatientListStatusEnum.REGISTERED)}>
                                Registrerade
                            </PatientListSecondaryButton>
                            <PatientListPrimaryButton
                                onClick={() => handleStatusChange(PatientListStatusEnum.TRANSFERRED)}>
                                Överförda
                            </PatientListPrimaryButton>
                        </>
                    }
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}>
                            <NewFormBtn
                                onClick={handleNewFormButtonClick}
                            >
                                Fyll i nytt formulär
                            </NewFormBtn>
                        </Col>
                        <Col sm={8}>
                            <PatientSearch onChange={handleSearchValueChange}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            {
                isLoading ? <LoadingView/> :
                    <>
                        <PatientTable patients={filteredPatients}/>
                        <Pagination
                            numberOfPatients={numberOfPatients}
                            rowsPerPage={rowsPerPaginatedPage}
                            activePage={activePage}
                            handleClick={handlePaginate}
                        />
                    </>
            }
            <br/>
            <AnonymizationInfoFooter
                numberOfDays={patientListStatus === PatientListStatusEnum.REGISTERED ? AnonymizationConstants.SickLeaveMaxNumberOfDaysInRegistered : AnonymizationConstants.SickLeaveMaxNumberOfDaysInTransferred}
                tabName={patientListStatus === PatientListStatusEnum.REGISTERED ? 'Registrerade' : 'Överförda'}
            />
        </>
    );
}

const PatientListPrimaryButton = styled(PrimaryButton)`
    margin: 3px;
`;

const PatientListSecondaryButton = styled(SecondaryButton)`
    margin: 3px;
`;

const NewFormBtn = styled(SecondaryButton)`
float:right;
`;

export default PatientList;
