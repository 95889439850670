import doktorse from './doktorseTheme';
import meliva from './melivaTheme';
import defaultTheme from './defaultTheme';

export const getTheme = () => {

    const theme = window.REACT_APP_THEME;
    switch (theme.trim()) {
        case 'doktorse':
            return doktorse;
        case 'meliva':
            return meliva;
        default:
            return defaultTheme;
    }
}