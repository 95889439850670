import React, {useEffect, useState} from "react";
import { DropDown, H4, LoadingView } from "@collabodoc/component-library";
import { useHistory} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {SITE_URLS} from "./enums/urls";

const ChooseCareCenter = ({careCenters, setCurrentCareCenter, isLoadingCareCenters}) => {
    const history = useHistory();

    const [selectedCareCenter, setSelectedCareCenter] = useState(null);
    
    useEffect(() => {
        if(careCenters && careCenters.length === 1)
        {
            setCurrentCareCenter(careCenters[0].id);
            history.push(SITE_URLS.PATIENTLIST);
        }
        else if (selectedCareCenter) {
            setCurrentCareCenter(selectedCareCenter);
            history.push(SITE_URLS.PATIENTLIST);
        }
    }, [selectedCareCenter])

    if (isLoadingCareCenters) {
        return <LoadingView/>;
    }


    return (
        <Container>
            <Row>
                <Col md={{size: 6, offset: 3}} className="chooseCareCenter">
                    <H4>Välj vårdcentral</H4>
                </Col>
            </Row>
            <Row>
                <Col md={{size: 6, offset: 3}}>
                    <DropDown
                        placeholder={"Välj vårdcentral"}
                        options={careCenters.map(x => ({ text: x.name, id: x.id }))}
                        handler={(id) => setSelectedCareCenter(id)}>
                    </DropDown>
                </Col>
            </Row>
            <br/>
        </Container>
    );
};
export default ChooseCareCenter;