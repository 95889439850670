import { CopyButtonSmall } from '@collabodoc/component-library';
import React from 'react';
import { CardBody, CardHeader, CardText, CardHeaderText, CertificateCard } from './StyledComponents/CardStyles';
import styled from "styled-components";

const OtherPatientInfoSection = ({ model }) => {

    return (
        <>
            <CertificateCard>
                <CardHeader>
                    <CardHeaderText>Övrig information från patienten</CardHeaderText>
                </CardHeader >
                <CardBody>
                    <CardText>{model.sectionText}</CardText>
                    <CopyButtonWrapper>
                        <CopyButtonSmall
                            feedback={"Patientinformation kopierad"}
                            textValue={model.sectionText}
                        />
                    </CopyButtonWrapper>
                </CardBody>
            </CertificateCard>
            <br />
        </>
    );
}

export default OtherPatientInfoSection;

const CopyButtonWrapper = styled.span`
    float: right;
`;