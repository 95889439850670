import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useContext } from 'react';
import { CareCenterContext, LoadingView, NavBar } from "@collabodoc/component-library";
import { EXTERNAL_URLS, SITE_URLS } from "../enums/urls";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';

const NavMenu = () => {
    const { removeCurrentCareCenter, currentCareCenterId, careCenters, setCurrentCareCenter, isLoadingCareCenters, } = useContext(CareCenterContext)
    const oidc = useReactOidc();
    const history = useHistory();

    const handleChange = (id) => {
        setCurrentCareCenter(id);
        history.push(SITE_URLS.PATIENTLIST);
    }

    const handleLogOut = () => {
        removeCurrentCareCenter();
        oidc.logout();
    };

    const theme = useContext(ThemeContext);

    const handleLogoClick = () => {
        if (EXTERNAL_URLS.CAREGIVER_PORTAL) {
            window.location.href = EXTERNAL_URLS.CAREGIVER_PORTAL;
        } else {
            history.push(SITE_URLS.PATIENTLIST)
        }
    }

    const items = [
        {
            label: "Mitt konto",
            url: `${EXTERNAL_URLS.MANAGE}?returnUrl=${window.location.href}`
        },
        {
            label: "Logga ut",
            handler: handleLogOut
        },
    ];

    if (isLoadingCareCenters) {
        return <LoadingView />;
    }

    let dropdown = null;
    if (careCenters && careCenters.length > 1 && currentCareCenterId) {
        dropdown = {
            selected: careCenters.find(cc => cc.id === currentCareCenterId).name,
            dropdownitems: careCenters.map(cc => ({ label: cc.name, id: cc.id, handler: () => handleChange(cc.id) }))
        };
    };
    
    return (
        <NavBar logo={theme.logo} logohandler={handleLogoClick} items={items} dropdown={dropdown} />
    );
};

export default NavMenu;
