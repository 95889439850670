const COLLABODOC_BACKEND_API = `${window.REACT_APP_API}`;
const CAREGIVER_PORTAL_URL = `${window.REACT_APP_CAREGIVER_PORTAL_URL}`;
const ENABLE_CAREGIVER_PORTAL = `${window.REACT_APP_ENABLE_CAREGIVER_PORTAL}`.toLocaleLowerCase() === 'true';
const USERSERVICE_URL = `${window.REACT_APP_USER_SERVICE_URL}`;
const EXTERNAL_URLS = {
    CAREGIVER_PORTAL: ENABLE_CAREGIVER_PORTAL ? CAREGIVER_PORTAL_URL : null,
    MANAGE: `${USERSERVICE_URL}/identity/manage`
}
const API_URLS = {
    CARECENTERS:
        `${USERSERVICE_URL}/api/carecenters`,
    PATIENTS: (careCenterId, culture) =>
        `${COLLABODOC_BACKEND_API}/api/${careCenterId}/sickleavepatientlist?culture=${culture}`,
    EMPLOYERSCERTIFICATEFORM_GET: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/${symptomFormId}`,
    EMPLOYERSCERTIFICATEFORM_SAVE:
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/save`,
    EMPLOYERSCERTIFICATEFORM_GETSICKLEAVEPERIODS: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/${symptomFormId}/sickleaveperiods`,
    EMPLOYERSCERTIFICATEFORM_SAVESICKLEAVEPERIOD:
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/sickleaveperiod/save`,
    EMPLOYERSCERTIFICATEFORM_ADDSICKLEAVEPERIOD: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/sickleaveperiod/add/${symptomFormId}`,
    EMPLOYERSCERTIFICATEFORM_DELETESICKLEAVEPERIOD: (symptomFormId, periodId) =>
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/sickleaveperiod/delete/${symptomFormId}/${periodId}`,
    EMPLOYERSCERTIFICATEFORM_TRANSFERRED: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/employerscertificateform/settotransferred/${symptomFormId}`,
    SICKLEAVEFORM_GET: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/sickleaveform/${symptomFormId}`,
    SICKLEAVEFORM_SAVE:
        `${COLLABODOC_BACKEND_API}/api/sickleaveform/save`,
    SICKLEAVEFORM_TRANSFERRED: (symptomFormId) =>
        `${COLLABODOC_BACKEND_API}/api/sickleaveform/settotransferred/${symptomFormId}`,
    PATIENT_DELETE: (careCenterId, patientId) =>
        `${COLLABODOC_BACKEND_API}/api/sickleaveform/delete/${careCenterId}/${patientId}`,
    SICKLEAVEFORM_SAVECOMMENT:
        `${COLLABODOC_BACKEND_API}/api/sickleaveform/setcomment`,
    DIAGNOSIS_DELETE: (symptomFormId, diagnosisId) =>  
        `${COLLABODOC_BACKEND_API}/api/diagnosis/${symptomFormId}/delete/${diagnosisId}`,
    DIAGNOSES: (careCenterId) =>
        `${COLLABODOC_BACKEND_API}/api/diagnosis/getall?careCenterId=${careCenterId}`,
    DIAGNOSIS_ADD:
        `${COLLABODOC_BACKEND_API}/api/diagnosis/add`,

};

const SITE_URLS = {
    CHOOSE_CARECENTER: "/valj-vardcentral",
    PATIENTLIST: "/patientlistan",
    SICKLEAVEFORM: "/lang-sjukskrivning/:sickLeaveFormId",
    EMPLOYERSCERTIFICATEFORM: '/kort-sjukskrivning/:sickLeaveFormId',
    AUTH_SIGNED_IN: "/auth/signed-in",
    AUTH_SIGNED_IN_SILENT: "/auth/signed-in-silent",
    AUTH_SIGNED_OUT: "/auth/signed-out",
};

export { SITE_URLS, API_URLS, EXTERNAL_URLS };
