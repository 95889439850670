import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextAreaWithLabelAndError } from '@collabodoc/component-library';
import { EmployersCertificateColumn } from '../../enums/Enums';
import { SectionHeader } from '../StyledComponents/CardStyles';


const EmployersCertificateSection = ({ section, onBlur, sectionTitle, textRef }) => {

    const [text, setText] = useState(section.sectionText || "");

    return (
        <>
            <Row>
                <Col>
                    <SectionHeader>{sectionTitle}</SectionHeader>
                    <TextAreaWithLabelAndError
                        ref={textRef}
                        value={text}
                        minRows={2}
                        handleBlur={() => onBlur(text, section.certificateColumn)}
                        handleChange={target => setText(target)}
                        whiteTextArea={section.certificateColumn !== EmployersCertificateColumn.DOCTOR_DISABILITY_ASSESSMENT}
                    />
                </Col>
            </Row>
            <br />
        </>
    );
}
export default EmployersCertificateSection;

