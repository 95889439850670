import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useDelete, usePost } from "@collabodoc/component-library";
import { faCalendarAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sv from "date-fns/locale/sv";
import React, { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { API_URLS } from "../../enums/urls";
import ReactDatePickerStyle from "./DatePickerGlobalStyle";

//unused ref needed because https://github.com/Hacker0x01/react-datepicker/issues/862
const DateField = forwardRef(({ value, onClick }, refs) => {
  return (
    <span onClick={onClick}>
      <CalendarIcon icon={faCalendarAlt} />
    </span>
  );
});

const SickLeaveTableRow = ({
  rowData,
  reFetch,
  sickLeaveFormId,
  validateRanges,
  handleOverlapError,
}) => {
  const { oidcUser } = useReactOidc();
  const [sickLeaveExtent, setExtent] = useState(rowData.sickLeaveExtent || 0);
  const [sickLeaveStart, setStart] = useState(
    rowData.sickLeaveStart && new Date(rowData.sickLeaveStart)
  );

  const [sickLeaveEnd, setEnd] = useState(
    rowData.sickLeaveEnd && new Date(rowData.sickLeaveEnd)
  );

  const { doPost: saveSickLeavePeriod } = usePost(
    API_URLS.EMPLOYERSCERTIFICATEFORM_SAVESICKLEAVEPERIOD,
    oidcUser.access_token
  );

  const { doDelete: deleteSickLeavePeriod } = useDelete(
    API_URLS.EMPLOYERSCERTIFICATEFORM_DELETESICKLEAVEPERIOD(
      sickLeaveFormId,
      rowData.periodId
    ),
    oidcUser.access_token
  );

  const handleSave = () => {
    let slp = {
      symptomFormId: parseInt(sickLeaveFormId),
      periodId: parseInt(rowData.periodId),
      sickLeaveStart:
        sickLeaveStart && new Date(sickLeaveStart).toLocaleDateString("sv"),
      sickLeaveEnd:
        sickLeaveEnd && new Date(sickLeaveEnd).toLocaleDateString("sv"),
      sickLeaveExtent: sickLeaveExtent && parseInt(sickLeaveExtent),
    };
    const shouldUpdate = validateRanges(slp);
    if (!shouldUpdate) {
      handleOverlapError(true);
      reFetch();
    } else {
      saveSickLeavePeriod(slp).then(() => reFetch());
    }
  };

  const handleSaveSickLeaveExtent = (e) => {
    const extent = e.target.value;
    if (extent > 100 || extent < 0) {
      if (extent > 100) {
        setExtent(100);
      }
      if (extent < 0) {
        setExtent(0);
      }
    } else {
      setExtent(extent);
    }
  };

  const removeSickLeavePeriod = () => {
    deleteSickLeavePeriod().then(() => reFetch());
  };

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  return (
    <>
      <TableRow key={rowData.periodId}>
        <TableData>
          <ReactDatePickerStyle />
          <ReactDatePicker
            customInput={<DateField />}
            dateFormat={"yyyy-MM-dd"}
            locale={sv}
            popperPlacement={"top"}
            selected={sickLeaveStart && sickLeaveStart}
            onChange={handleChange}
            startDate={sickLeaveStart && sickLeaveStart}
            endDate={sickLeaveEnd && sickLeaveEnd}
            selectsRange
            fixedHeight
            onCalendarClose={handleSave}
          />
        </TableData>
        <TableData>
          <span>
            {sickLeaveStart && sickLeaveStart.toLocaleDateString("sv")}
          </span>
        </TableData>
        <TableData>
          <span>{sickLeaveEnd && sickLeaveEnd.toLocaleDateString("sv")}</span>
        </TableData>
        <TableData>
          <SickLeaveExtent
            value={sickLeaveExtent}
            onChange={handleSaveSickLeaveExtent}
            onBlur={handleSave}
          />
        </TableData>
        <TableData>
          <Icon icon={faTrash} onClick={removeSickLeavePeriod} />
        </TableData>
      </TableRow>
    </>
  );
};

const SickLeaveExtent = styled.input.attrs({
  type: "number",
  step: 25,
  min: 0,
  max: 100,
})`
  border: 1px solid ${({ theme }) => theme.searchbar.borderColor};
  text-align: center;

  :focus-visible {
    outline: none;
  }
`;

const TableRow = styled.tr`
  :nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.patientList.oddRow.backgroundColor};
    color: ${({ theme }) => theme.patientList.color};
  }

  :nth-child(even) {
    background-color: ${({ theme }) =>
      theme.patientList.evenRow.backgroundColor};
    color: ${({ theme }) => theme.patientList.color};
  }
`;

const TableData = styled.td`
  padding: 3px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const CalendarIcon = styled(Icon)`
  margin-left: 25px;
`;

export default SickLeaveTableRow;
