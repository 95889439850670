import { defaultTheme as defaultComponentLibraryTheme } from "@collabodoc/component-library";
import logo from '../Images/Logo/collabodoc_light.png'

const defaultTheme = {
    ...defaultComponentLibraryTheme,
    logo: logo,
    searchbar: {
        backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
        borderColor: defaultComponentLibraryTheme.colors.primary
    },
    copybutton: {
        backgroundColor: defaultComponentLibraryTheme.colors.primary,
        borderColor: defaultComponentLibraryTheme.colors.primary
    },
    card: {
        cardHeader: {
            color: 'black',
            fontWeight: '700'
        }
    },
    sectionHeader:{
        color: defaultComponentLibraryTheme.colors.primary
    },
    typeahead: {
        backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
        borderColor: defaultComponentLibraryTheme.colors.secondary,
        color: defaultComponentLibraryTheme.colors.primary
    },
    patientList: {
        row: {
            hover: {
                backgroundColor: defaultComponentLibraryTheme.colors.secondary,
                color: 'black'
            }
        },
        oddRow: {
            backgroundColor: defaultComponentLibraryTheme.colors.tertiary
        },
        evenRow: {
            backgroundColor: defaultComponentLibraryTheme.colors.white
        },
        pagination: {
            lineHeight: '35px',
            active:{
                backgroundColor: defaultComponentLibraryTheme.colors.focus,
                color: defaultComponentLibraryTheme.colors.primary,
            }
        },
        color: 'black'
    },
    datePicker: {
        hover: {
            backgroundColor: defaultComponentLibraryTheme.colors.tertiary,
            color: defaultComponentLibraryTheme.colors.primary,
        },
        selected: {
            backgroundColor: defaultComponentLibraryTheme.colors.secondary,
            color: defaultComponentLibraryTheme.colors.primary,
        },
        today: {
            backgroundColor: defaultComponentLibraryTheme.colors.primary,
            color: 'white',
        }
    },
    tippyTheme: {
        backgroundColor: defaultComponentLibraryTheme.colors.primary
    }
};

export default defaultTheme;


