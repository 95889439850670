import {Document, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import {OccupationEnum} from "../enums/Enums";

const GetOccupationEstablishmentRow = ({document}) => {
  switch (document.occupation) {
    case OccupationEnum.UNEDMPLOYED:
      return null;
    case OccupationEnum.STUDENT:
      return null;
    default:
      return (
          <>
            Arbetsgivare: <Text>{document.employer}</Text>
          </>
      );

  }
};

export const PrintableEmployersCertificate = ({theme, document}) => {

  const styleSheet = StyleSheet.create({
    body: {
      paddingTop: 50,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    image: {
      height: 40,
    },
    title: {
      color: theme.patientList.color,
      fontSize: 20,
      paddingBottom: 2,
    },
    signature: {
      marginTop: 20,
      fontSize: 10,
    },
    fixedHeader: {
      top: 30,
      left: 20,
      position: "absolute",
      fontSize: 9,
      textAlign: "right",
    },
    fixedFooter: {
      position: "absolute",
      fontSize: 8,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: theme.patientList.color,
    },
    subtitle: {
      fontSize: 12,
      marginTop: 20,
      color: theme.patientList.color,
    },
    patientInfo: {
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: "#f1f1f1",
      backgroundColor: "#f1f1f1",
      color: theme.patientList.color,
      marginTop: 10,
    },
    patientInfoText: {
      fontSize: 10,
      marginLeft: 10,
      marginBottom: 5,
    },
    view: {
      margin: 30,
    },
    line: {
      marginTop: 30,
      color: theme.patientList.color,
    },
    userName: {
      marginTop: 10,
      fontSize: 10,
    },
    userRole: {
      fontSize: 10,
    },
    patientData: {
      fontSize: 10,
      marginTop: 5,
    },
  });

  return (
    <Document>
      <Page style={styleSheet.body}>
        <Text style={styleSheet.fixedHeader}>{document.today}</Text>
        <Text style={styleSheet.title}>
          Intyg till {document.occupationEstablishment}
        </Text>
        <View style={styleSheet.patientInfo}>
          <Text style={styleSheet.patientInfoText}>
            <GetOccupationEstablishmentRow document={document}/>
          </Text>
          <Text style={styleSheet.patientInfoText}>
            Namn: <Text>{document.patientName}</Text>
          </Text>
          <Text style={styleSheet.patientInfoText}>
            Personnummer: <Text>{document.personId}</Text>
          </Text>
        </View>
        {document.patientAbilityDescription === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>
              Patienten kan utföra följande arbetsuppgifter
            </Text>
            <Text style={styleSheet.patientData}>
              {document.patientAbilityDescription}
            </Text>
          </View>
        )}
        {document.patientDisabilityDescription === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>
              Patienten kan inte utföra följande arbetsuppgifter
            </Text>
            <Text style={styleSheet.patientData}>
              {document.patientDisabilityDescription}
            </Text>
          </View>
        )}
        {document.patientDisabilityWhyDescription === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>
              Patientens beskrivning av varför ovanstående arbetsuppgifter ej
              kan utföras
            </Text>
            <Text style={styleSheet.patientData}>
              {document.patientDisabilityWhyDescription}
            </Text>
          </View>
        )}
        {document.patientWorkplaceAdaptations === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>
              Patientens beskrivning av anpassningar som skulle möjliggöra hel-
              eller deltidsarbete
            </Text>
            <Text style={styleSheet.patientData}>
              {document.patientWorkplaceAdaptations}
            </Text>
          </View>
        )}
        {document.patientTaxi === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>Taxiresor</Text>
            <Text style={styleSheet.patientData}>{document.patientTaxi}</Text>
          </View>
        )}
        {document.diagnoses === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>Diagnos</Text>
            <Text style={styleSheet.patientData}>{document.diagnoses}</Text>
          </View>
        )}
        {document.doctorDisabilityAssessment === "" ? (
          <View />
        ) : (
          <View>
            <Text style={styleSheet.subtitle}>
              Min bedömning av patientens tillstånd och nedsatta arbetsförmåga
            </Text>
            <Text style={styleSheet.patientData}>
              {document.doctorDisabilityAssessment}
            </Text>
          </View>
        )}
        {document.sickLeavePeriods.length > 0 && (
          <View>
            <Text style={styleSheet.subtitle}>Sjukskrivningsperiod(er)</Text>
            <Text style={styleSheet.patientData}>
              {document.sickLeavePeriods}
            </Text>
          </View>
        )}
        <Text style={styleSheet.signature}>Intygas i tjänsten</Text>
        <Text style={styleSheet.line}>__________________________</Text>
        <Text style={styleSheet.userName}>{document.userName}</Text>
        <Text style={styleSheet.userRole}>{document.userRole}</Text>
        <View style={styleSheet.fixedFooter}>
          <Text>{document.careCenterName}</Text>
          <Text>{document.careCenterAddress}</Text>
          {document.careCenterPhone && (
            <Text>Telefon: {document.careCenterPhone}</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};
