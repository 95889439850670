import React from 'react';
import styled from 'styled-components';

const PatientSearch = ({ onChange }) => {

    return (
        <SearchBar
            type="search"
            id="patientSearch"
            placeholder="Sök"
            aria-controls="patientListTable"
            onChange={e => onChange(e)} />
    );
}

export default PatientSearch;


const SearchBar = styled.input`
    padding: 5px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.searchbar.backgroundColor};
    border: 1px solid ${({ theme }) => theme.searchbar.borderColor};
    width: 100%;
    :focus-visible {
        outline: none;
    }
`