import {
    defaultTheme as defaultComponentLibraryTheme,
    meliva as melivaComponentLibraryTheme
} from "@collabodoc/component-library";
import logo from '../Images/Logo/meliva_light.png'

const meliva = {
    ...melivaComponentLibraryTheme,
    logo: logo,
    searchbar: {
        backgroundColor: '#e0efec',
        borderColor: melivaComponentLibraryTheme.colors.primary
    },
    copybutton: {
        backgroundColor: melivaComponentLibraryTheme.colors.primary,
        borderColor: melivaComponentLibraryTheme.colors.primary
    },
    card: {
        cardHeader: {
            color: 'black',
            fontWeight: 'normal'
        }
    },
    sectionHeader:{
        color: 'black'
    },
    typeahead: {
        backgroundColor: '#e0efec',
        borderColor: melivaComponentLibraryTheme.colors.primary,
        color: melivaComponentLibraryTheme.colors.primary,
    },
    patientList: {
        row: {
            hover: {
                backgroundColor: '#c2dfda',
                color: melivaComponentLibraryTheme.colors.primary,
            }
        },
        oddRow: {
            backgroundColor: '#f1f1f1'
        },
        evenRow: {
            backgroundColor: '#d6e9e6'
        },
        pagination: {
            lineHeight: '32px',
            active:{
                borderColor:  melivaComponentLibraryTheme.colors.focus,
                backgroundColor: melivaComponentLibraryTheme.colors.focus,
                color: 'white'
            }
        },
        color: 'black'
    },
    datePicker: {
        hover: {
            backgroundColor: '#e0efec',
            color: 'black',
        },
        selected: {
            backgroundColor: melivaComponentLibraryTheme.colors.success,
            color: 'white',
        },
        today: {
            backgroundColor: melivaComponentLibraryTheme.colors.primary,
            color: 'white',
        }
    },
    tippyTheme: {
        backgroundColor: melivaComponentLibraryTheme.colors.primary
    }
};

export default meliva;


