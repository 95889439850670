import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderInfo,
  CardHeaderText,
  CertificateCard,
} from "../StyledComponents/CardStyles";
import DoubleSection from "./DoubleSection";

const AbilityDisabilitySection = ({
  disabilitiesDoctor,
  disabilitiesPatient,
  activityLimitationsDoctor,
  activityLimitationsPatient,
  handleFormChange,
  onBlur,
  showAllValidationErrors,
}) => {
  const handleDisabilitiesPatientChange = (value) => {
    handleFormChange({
      disabilitiesPatient: { ...disabilitiesPatient, sectionText: value },
    });
  };

  const handleDisabilitiesDoctorChange = (value) => {
    handleFormChange({
      disabilitiesDoctor: { ...disabilitiesDoctor, sectionText: value },
    });
  };

  const handleActivityLimitationsPatientChange = (value) => {
    handleFormChange({
      activityLimitationsPatient: {
        ...activityLimitationsPatient,
        sectionText: value,
      },
    });
  };

  const handleActivityLimitationsDoctorChange = (value) => {
    handleFormChange({
      activityLimitationsDoctor: {
        ...activityLimitationsDoctor,
        sectionText: value,
      },
    });
  };

  return (
    <>
      <CertificateCard>
        <CardHeader>
          <CardHeaderText>Sjukdomens konsekvenser för patienten</CardHeaderText>
          <CardHeaderInfo>(Kopieras till Webcert)</CardHeaderInfo>
        </CardHeader>
        <CardBody>
          <DoubleSection
            patientSection={disabilitiesPatient}
            doctorSection={disabilitiesDoctor}
            sectionTitle={
              "Beskriv undersökningsfynd, testresultat och observationer"
            }
            doctorSectionTitle={"Mina observationer och undersökningsresultat"}
            handlePatientChange={handleDisabilitiesPatientChange}
            handleDoctorChange={handleDisabilitiesDoctorChange}
            showAllValidationErrors={showAllValidationErrors}
            onBlur={onBlur}
            minRows={2}
          />

          <DoubleSection
            patientSection={activityLimitationsPatient}
            doctorSection={activityLimitationsDoctor}
            sectionTitle={
              "Beskriv vad patienten inte kan göra på grund av sin sjukdom. Ange vad uppgiften grundas på"
            }
            doctorSectionTitle={"Min bedömning"}
            handlePatientChange={handleActivityLimitationsPatientChange}
            handleDoctorChange={handleActivityLimitationsDoctorChange}
            showAllValidationErrors={showAllValidationErrors}
            onBlur={onBlur}
            minRows={2}
          />
        </CardBody>
      </CertificateCard>
      <br />
    </>
  );
};

export default AbilityDisabilitySection;
