import {useReactOidc} from "@axa-fr/react-oidc-context";
import {LoadingView, SecondaryButton, SuccessButton, useGet, usePost,} from "@collabodoc/component-library";
import {faAngleLeft, faCheck, faDownload,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {reverse} from "named-urls";
import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import {SickLeaveType} from "../../enums/Enums";
import {API_URLS, SITE_URLS} from "../../enums/urls";
import CommentCard from "../CommentCard";
import ContactPersonCard from "../ContactPersonCard";
import DiagnosisSection from "../DiagnosisSection";
import OtherPatientInfoSection from "../OtherPatientInfoSection";
import {PatientCompletedModal} from "../PatientCompletedModal";
import {CardBody, CardHeader, CardHeaderText, CertificateCard, SectionHeader,} from "../StyledComponents/CardStyles";
import Footer from "../StyledComponents/Footer";
import EmployersCertificateSection from "./EmployersCertificateSection";
import {generatePdfDocument, getOccupationEstablishment,} from "./PdfDataGenerator";
import SickLeavePeriodTable from "./SickLeavePeriodTable";

const EmployersCertificateForm = () => {
  document.body.style.backgroundColor = "#f1f1f1";
  const history = useHistory();
  const {oidcUser} = useReactOidc();
  const {sickLeaveFormId} = useParams();
  const [diagnosisSectionModel, setDiagnosisSectionModel] = useState({});
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [sickLeavePeriods, setSickLeavePeriods] = useState([]);

  const patientAbilityRef = useRef();
  const patientDisabilityRef = useRef();
  const patientDisabilityWhyRef = useRef();
  const workplaceAdaptationsRef = useRef();
  const taxiRef = useRef();
  const assessmentRef = useRef();

  const { response: employersCertificateForm, isLoading } = useGet(
    API_URLS.EMPLOYERSCERTIFICATEFORM_GET(sickLeaveFormId),
    oidcUser.access_token
  );

  const { doPost: saveSickLeaveForm } = usePost(
    API_URLS.EMPLOYERSCERTIFICATEFORM_SAVE,
    oidcUser.access_token
  );

  useEffect(() => {
    if (!isLoading) {
      setDiagnosisSectionModel(employersCertificateForm.diagnosisSectionModel);
      setSickLeavePeriods(employersCertificateForm.sickLeavePeriods);
    }
  }, [employersCertificateForm, isLoading]);

  const handleSave = async (text, sickLeaveCertificateColumn) => {
    await saveSickLeaveForm({
      SymptomFormId: parseInt(sickLeaveFormId),
      Text: text,
      EmployersCertificateColumn: sickLeaveCertificateColumn,
    });
  };

  const addDiagnosis = (diagnosis) => {
    let newdiagnosisSectionModel = { ...diagnosisSectionModel };
    newdiagnosisSectionModel.diagnoseItems.push(diagnosis);
    setDiagnosisSectionModel(newdiagnosisSectionModel);
  };

  const deleteDiagnosis = (diagnosisId) => {
    let newdiagnosisSectionModel = { ...diagnosisSectionModel };
    newdiagnosisSectionModel.diagnoseItems =
      diagnosisSectionModel.diagnoseItems.filter(
        (diagnosis) => diagnosis.id !== diagnosisId
      );
    setDiagnosisSectionModel(newdiagnosisSectionModel);
  };

  const handleBack = () => {
    if (employersCertificateForm.sickLeaveType === SickLeaveType.LONG) {
      history.push(
        reverse(SITE_URLS.SICKLEAVEFORM, {
          sickLeaveFormId: employersCertificateForm.symptomFormId,
        })
      );
    } else history.push(SITE_URLS.PATIENTLIST);
  };

  const togglePatientCompletedModal = () =>
    setShowCompletedModal(!showCompletedModal);
  const hidePatientCompletedModal = () => {
    setShowCompletedModal(false);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Form>
        <Col lg={3}>
          <OtherPatientInfoSection
            model={employersCertificateForm.otherPatientInfo}
          />
          <ContactPersonCard sickLeaveForm={employersCertificateForm} />
          <CommentCard
            comment={employersCertificateForm.comment}
            sickLeaveFormId={employersCertificateForm.symptomFormId}
          />
        </Col>
        <Col lg={6}>
          <CertificateCard>
            <CardHeader>
              <CardHeaderText>
                Intyg till {getOccupationEstablishment(employersCertificateForm.occupation)}
              </CardHeaderText>
            </CardHeader>
            <CardBody>
              <EmployersCertificateSection
                section={employersCertificateForm.patientAbilityDescription}
                onBlur={handleSave}
                sectionTitle={"Patienten kan utföra följande arbetsuppgifter"}
                textRef={patientAbilityRef}
              />
              <EmployersCertificateSection
                section={employersCertificateForm.patientDisabilityDescription}
                onBlur={handleSave}
                sectionTitle={
                  "Patienten kan inte utföra följande arbetsuppgifter"
                }
                textRef={patientDisabilityRef}
              />
              <EmployersCertificateSection
                section={
                  employersCertificateForm.patientDisabilityWhyDescription
                }
                onBlur={handleSave}
                sectionTitle={
                  "Patientens beskrivning av varför ovanstående arbetsuppgifter ej kan utföras"
                }
                textRef={patientDisabilityWhyRef}
              />
              <EmployersCertificateSection
                section={employersCertificateForm.patientWorkplaceAdaptations}
                onBlur={handleSave}
                sectionTitle={
                  "Patientens beskrivning av anpassningar som skulle möjliggöra hel- eller deltidsarbete"
                }
                textRef={workplaceAdaptationsRef}
              />
              <EmployersCertificateSection
                section={employersCertificateForm.patientTaxi}
                onBlur={handleSave}
                sectionTitle={"Taxiresor"}
                textRef={taxiRef}
              />
              <DiagnosisSection
                onDiagnosisDelete={deleteDiagnosis}
                onDiagnosisAdd={addDiagnosis}
                symptomFormId={employersCertificateForm.symptomFormId}
                model={diagnosisSectionModel}
              />
              <br />
              <EmployersCertificateSection
                section={employersCertificateForm.doctorDisabilityAssessment}
                onBlur={handleSave}
                sectionTitle={
                  "Min bedömning av patientens tillstånd och nedsatta arbetsförmåga"
                }
                textRef={assessmentRef}
              />
              <SectionHeader>Sjukskrivningsperioder</SectionHeader>
              {sickLeavePeriods && (
                <SickLeavePeriodTable
                  sickLeaveFormId={sickLeaveFormId}
                  updateSickLeavePeriods={setSickLeavePeriods}
                  sickLeavePeriods={sickLeavePeriods}
                />
              )}
            </CardBody>
          </CertificateCard>
        </Col>
      </Form>

      <Footer>
        <SecondaryButton onClick={handleBack}>
          <ButtonIcon icon={faAngleLeft} />
          {employersCertificateForm.sickLeaveType === SickLeaveType.SHORT
            ? "Tillbaka till patientlistan"
            : "Tillbaka till sjukskrivningsformuläret"}
        </SecondaryButton>

        <CompletedBtn onClick={togglePatientCompletedModal}>
          <ButtonIcon icon={faCheck} />
          Klar
        </CompletedBtn>
        <PatientCompletedModal
          sickLeaveForm={employersCertificateForm}
          toggleCompletedModal={togglePatientCompletedModal}
          showCompletedModal={showCompletedModal}
          hideCompletedModal={hidePatientCompletedModal}
        />
        <PDFButton
          onClick={() =>
            generatePdfDocument(
              employersCertificateForm,
              oidcUser,
              patientAbilityRef,
              patientDisabilityRef,
              patientDisabilityWhyRef,
              workplaceAdaptationsRef,
              taxiRef,
              assessmentRef,
              diagnosisSectionModel,
              sickLeavePeriods
            )
          }
        >
          <ButtonIcon icon={faDownload} />
          Ladda ner intyg för utskrift
        </PDFButton>
      </Footer>
    </>
  );
};

const PDFButton = styled(SecondaryButton)`
  margin-right: 5px;
  float: right;
`;

const Form = styled(Row)`
  margin-bottom: 55px;
`;

const CompletedBtn = styled(SuccessButton)`
  float: right;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export default EmployersCertificateForm;
