
import styled from "styled-components";

const Footer = styled.nav`
background-color: #e4e4e4;
border: 1px solid #CCC;
position: fixed;
right: 0;
bottom: 0;
left: 0;
z-index: 1030;
padding: 10px;
`;

export default Footer;