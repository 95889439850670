import { createGlobalStyle } from "styled-components";

const ReactDatePickerStyle = createGlobalStyle`
.react-datepicker{
    border-radius: 0px;
}

.react-datepicker__day--keyboard-selected{
    background: none;
    color: black;
}

.react-datepicker__day--today {
    border-radius: 50%;
    background-color:  ${({ theme }) => theme.datePicker.today.backgroundColor};
    color: ${({ theme }) => theme.datePicker.today.color};   
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range)

{
    border-radius: 50%;
    background-color:  ${({ theme }) => theme.datePicker.selected.backgroundColor};
    color: ${({ theme }) => theme.datePicker.selected.color};
}

.react-datepicker__day:hover{
    border-radius: 50%;
    background-color:  ${({ theme }) => theme.datePicker.hover.backgroundColor};
    color: ${({ theme }) => theme.datePicker.hover.color};
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
  color: #ccc;
}
`;

export default ReactDatePickerStyle;



