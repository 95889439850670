import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {ClearCacheProvider} from "react-clear-cache";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <ClearCacheProvider duration={50000}>
        <BrowserRouter basename={baseUrl}>
            <App/>
        </BrowserRouter>
    </ClearCacheProvider>,
    rootElement);


registerServiceWorker();
