import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  LoadingView,
  SecondaryButton,
  SuccessButton,
  useGet,
  usePost,
} from "@collabodoc/component-library";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faAngleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reverse } from "named-urls";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { notify } from "react-notify-toast";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { API_URLS, SITE_URLS } from "../../enums/urls";
import { isNullOrWhitespace } from "../../utils/helpers";
import CommentCard from "../CommentCard";
import ContactPersonCard from "../ContactPersonCard";
import { getOccupationEstablishment } from "../EmployersCertificate/PdfDataGenerator";
import OtherPatientInfoSection from "../OtherPatientInfoSection";
import { PatientCompletedModal } from "../PatientCompletedModal";
import Footer from "../StyledComponents/Footer";
import AbilityDisabilitySection from "./AbilityDisabilitySection";
import ActionSection from "./ActionSection";
import EmptySection from "./EmptySection";
import OccupationSection from "./OccupationSection";
import OtherSickLeaveInfoSection from "./OtherSickLeaveInfoSection";

const SickLeaveForm = () => {
  document.body.style.backgroundColor = "#f1f1f1";
  const theme = useContext(ThemeContext);
  const { oidcUser } = useReactOidc();
  const { sickLeaveFormId } = useParams();
  const history = useHistory();
  const { response: sickLeaveForm, isLoading } = useGet(
    API_URLS.SICKLEAVEFORM_GET(sickLeaveFormId),
    oidcUser.access_token
  );
  const { doPost: saveSickLeaveForm } = usePost(
    API_URLS.SICKLEAVEFORM_SAVE,
    oidcUser.access_token
  );
  const [showCompletedModal, setShowCompletedModal] = useState(false);

  const [form, setForm] = useState({});
  const [showAllValidationErrors, setShowAllValidationErrors] = useState(false);

  useEffect(() => {
    setForm(sickLeaveForm);
  }, [sickLeaveForm]);

  const handleFormChange = (formSection) => {
    const newForm = { ...form, ...formSection };
    setForm(newForm);
  };

  const handleSave = async (text, columnId) => {
    saveSickLeaveForm({
      SymptomFormId: parseInt(sickLeaveFormId),
      Text: text,
      SickLeaveCertificateColumn: columnId,
    });
  };

  const getEmployersCert = (e) => {
    history.push(
      reverse(SITE_URLS.EMPLOYERSCERTIFICATEFORM, {
        sickLeaveFormId: sickLeaveForm.symptomFormId,
      })
    );
  };

  const handleBack = () => {
    history.push(SITE_URLS.PATIENTLIST);
  };

  const handleCompletedButtonClick = () => {
    if (
      isNullOrWhitespace(form.disabilitiesDoctor.sectionText) ||
      isNullOrWhitespace(form.activityLimitationsDoctor.sectionText)
    ) {
      const style = {
        background: theme.colors.danger,
        text: theme.colors.white,
      };
      const timeout = 2000;
      notify.show(
        "Vänligen fyll i obligatoriska fält",
        "custom",
        timeout,
        style
      );
      setShowAllValidationErrors(true);
    } else {
      setShowCompletedModal(true);
    }
  };

  const hidePatientCompletedModal = () => {
    setShowCompletedModal(false);
  };
  const togglePatientCompletedModal = () =>
    setShowCompletedModal(!showCompletedModal);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Form>
        <Col lg={3}>
          <OtherPatientInfoSection model={sickLeaveForm.otherPatientInfo} />
          <ContactPersonCard sickLeaveForm={sickLeaveForm} />
          <CommentCard
            comment={sickLeaveForm.comment}
            sickLeaveFormId={sickLeaveForm.symptomFormId}
          />
        </Col>
        <Col lg={6}>
          <EmptySection mandatory={false} header={"Smittbärarpenning"} />
          <EmptySection
            mandatory={true}
            header={"Grund för medicinskt underlag"}
          />
          <OccupationSection
            section={form.workInfo}
            occupation={form.occupation}
            handleFormChange={handleFormChange}
            onBlur={handleSave}
          />
          <EmptySection mandatory={true} header={"Diagnos"} />
          <AbilityDisabilitySection
            disabilitiesDoctor={form.disabilitiesDoctor}
            disabilitiesPatient={form.disabilitiesPatient}
            activityLimitationsDoctor={form.activityLimitationsDoctor}
            activityLimitationsPatient={form.activityLimitationsPatient}
            handleFormChange={handleFormChange}
            onBlur={handleSave}
            showAllValidationErrors={showAllValidationErrors}
          />
          <EmptySection mandatory={false} header={"Medicinsk behandling"} />
          <EmptySection mandatory={true} header={"Bedömning"} />
          <ActionSection
            section={form.previousTreatmentPatient}
            handleFormChange={handleFormChange}
            onBlur={handleSave}
          />
          <OtherSickLeaveInfoSection
            section={form.otherSickLeaveInfo}
            handleFormChange={handleFormChange}
            onBlur={handleSave}
          />
          <EmptySection mandatory={false} header={"Kontakt"} />
          <EmptySection mandatory={false} header={"Vårdenhetens adress"} />
        </Col>
      </Form>
      <Footer>
        <SecondaryButton onClick={handleBack}>
          <ButtonIcon icon={faAngleLeft} />
          Tillbaka till patientlistan
        </SecondaryButton>

        <CompletedBtn onClick={handleCompletedButtonClick}>
          <ButtonIcon icon={faCheck} />
          Klar
        </CompletedBtn>
        <PatientCompletedModal
          sickLeaveForm={sickLeaveForm}
          toggleCompletedModal={togglePatientCompletedModal}
          showCompletedModal={showCompletedModal}
          hideCompletedModal={hidePatientCompletedModal}
        />
        <EmployersCertificateBtn onClick={getEmployersCert}>
          <ButtonIcon icon={faFileAlt} />
          Intyg till {getOccupationEstablishment(sickLeaveForm.occupation)}
        </EmployersCertificateBtn>
      </Footer>
    </>
  );
};

const Form = styled(Row)`
  margin-bottom: 35px;
`;

const EmployersCertificateBtn = styled(SecondaryButton)`
  float: right;
  margin-right: 5px;
`;

const CompletedBtn = styled(SuccessButton)`
  float: right;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;
export default SickLeaveForm;
