import React, { useContext, useState } from "react";
import { usePut, CareCenterContext, TextAreaWithLabelAndError} from "@collabodoc/component-library";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { API_URLS } from "../enums/urls";
import { CardHeader, CardBody, CardHeaderText, CertificateCard } from './StyledComponents/CardStyles';

const CommentCard = ({ comment, sickLeaveFormId }) => {

    const [commentState, setCommentState] = useState(comment || "");
    const { oidcUser } = useReactOidc();
    const { currentCareCenterId } = useContext(CareCenterContext);
    const { doPut: saveSickLeaveComment } = usePut(API_URLS.SICKLEAVEFORM_SAVECOMMENT, oidcUser.access_token);

    const updateComment = async ({ commentText }) => {

        saveSickLeaveComment({
            SymptomFormId: parseInt(sickLeaveFormId),
            Comment: commentText,
            CareCenterId: currentCareCenterId
        })
    };

    return (
        <>
            <CertificateCard>
                <CardHeader>
                    <CardHeaderText>Kommentar</CardHeaderText>
                </CardHeader>
                <CardBody>
                    <TextAreaWithLabelAndError
                        value = {commentState}
                        handleBlur={() => updateComment({ commentText: commentState })}
                        handleChange={(e) => setCommentState(e)}
                        minRows={3}
                        maxRows={5}
                        maxLength={128} />
                </CardBody>
            </CertificateCard>
        </>
    );
}
export default CommentCard;
