import React from 'react';
import { CardHeader, CardHeaderInfo, CardHeaderText, Asterisk, CertificateCard } from '../StyledComponents/CardStyles';

const SickLeaveFormEmptySection = ({ mandatory, header }) => {
    return (
        <>
            <CertificateCard>
                <CardHeader>
                    <CardHeaderText><Asterisk>{mandatory ? "*" : ""}</Asterisk>{header}</CardHeaderText>
                    <CardHeaderInfo>(Fylls i direkt i Webcert)</CardHeaderInfo>
                </CardHeader>
            </CertificateCard>
            <br />
        </>
    );
}

export default SickLeaveFormEmptySection;
