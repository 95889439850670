import { P, TextAreaWithLabelAndError } from "@collabodoc/component-library";
import React, { useRef } from "react";
import styled from "styled-components";
import CopyButton from "../CopyButton";
import {
  Asterisk,
  CardBody,
  CardHeader,
  CardHeaderText,
  CertificateCard,
} from "../StyledComponents/CardStyles";

const SickLeaveFormActionSection = ({ section, onBlur, handleFormChange }) => {

    const textRef = useRef();

    const handleTextChange = (value) => {
        handleFormChange({ previousTreatmentPatient: { ...section, sectionText: value } });
    };


    return (
        <>
            <CertificateCard >
                <CardHeader>
                    <CardHeaderText>Åtgärder</CardHeaderText>
                </CardHeader>
                <CardBody>
                    <Asterisk>*</Asterisk>
                    <Label>Här kan du ange åtgärder som du tror skulle göra det lättare för patienten att återgå i arbete. <LabelInfo>(Kopieras till Webcert)</LabelInfo></Label>
                    <br />
                    <CheckBox /><DisabledText>Inte aktuellt</DisabledText><br />
                    <CheckBox /><DisabledText>Arbetsträning</DisabledText><br />
                    <CheckBox /><DisabledText>Arbetsanpassning</DisabledText><br />
                    <CheckBox /><DisabledText>Söka nytt arbete</DisabledText><br />
                    <CheckBox /><DisabledText>Besök på arbetsplatsen</DisabledText><br />
                    <CheckBox /><DisabledText>Ergonomisk bedömning</DisabledText><br />
                    <CheckBox /><DisabledText>Hjälpmedel</DisabledText><br />
                    <CheckBox /><DisabledText>Konflikthantering</DisabledText><br />
                    <CheckBox /><DisabledText>Kontakt med företagshälsovård</DisabledText><br />
                    <CheckBox /><DisabledText>Omfördelning av företagshälsovård</DisabledText><br />
                    <CheckBox checked="checked"/><DisabledText>Övrigt</DisabledText><br />
                    <br />
                    <Asterisk>*</Asterisk>
                    <Label>Här kan du ange åtgärder som du tror skulle göra det lättare för patienten att återgå i arbete. <LabelInfo>(Kopieras till Webcert)</LabelInfo></Label>
                    <CopyButton
                        getText={() => textRef.current.value}
                        feedback={"Åtgärder kopierat"}
                    />
                    <TextAreaWithLabelAndError
                        value={section.sectionText}
                        ref={textRef}
                        handleBlur={() => onBlur(section.sectionText, section.certificateColumn)}
                        handleChange={handleTextChange}
                        minRows={2}
                        whiteTextArea={true}
                    />
                </CardBody>
            </CertificateCard>
            <br />
        </>
    );
}

const CheckBox = styled.input.attrs({
    type: 'checkbox',
    disabled: true
})`
  margin-right:5px;
 `;

const DisabledText = styled.span`
color: #555;
`;

const Label = styled.label`
display:inline;
margin-bottom: 0;
font-weight: bold;
`;

const LabelInfo = styled(P)`
display: inline;
margin: 0;
`;

export default SickLeaveFormActionSection;
