import React from "react";
import {useReactOidc} from "@axa-fr/react-oidc-context";
import {H2, SecondaryButton, SuccessButton, usePut} from "@collabodoc/component-library";
import {Modal} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {API_URLS, SITE_URLS} from "../enums/urls";

export const PatientCompletedModal = ({ sickLeaveForm, toggleCompletedModal, showCompletedModal, hideCompletedModal }) => {

    const history = useHistory();
    const { oidcUser } = useReactOidc();
    const { doPut } = usePut(API_URLS.SICKLEAVEFORM_TRANSFERRED(parseInt(sickLeaveForm.symptomFormId)), oidcUser.access_token);

    const handleClick = (e) => {
        e.stopPropagation();
        doPut()
            .then(() => history.push(SITE_URLS.PATIENTLIST))
    }

    return (
        <Modal show={showCompletedModal} onHide={hideCompletedModal}>
            <Modal.Header><H2>Är du helt klar?</H2></Modal.Header >
            <Modal.Body>
                Har du fört över allt du behöver till aktuellt journalsystem / sjukskrivningsintyg för <strong>{sickLeaveForm.patientName}, {sickLeaveForm.personId}</strong>
            </Modal.Body>
            <Modal.Footer className="textCenter">
                <SecondaryButton
                    onClick={toggleCompletedModal}>
                    Avbryt
                </SecondaryButton>
                <SuccessButton
                    onClick={handleClick}>
                    Ja, jag är klar
                </SuccessButton>
            </Modal.Footer>
        </Modal>

    );
}
