import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { DeleteModal } from "./DeleteModal";

const DropDownMenu = ({ rowData, showMenu, toggleMenu, setOpen, handleRowClick }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleClickOutside = useOnclickOutside(() => {
        setOpen(false);
    });

    const toggleDeleteModal = () => { setShowDeleteModal(!showDeleteModal) };
    
    const copyTextToClipboard = (copyText) => {
        navigator.clipboard.writeText(copyText);
    }

    return (
        <>
            <DeleteModal
                rowData={rowData}
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                hideDeleteModal={() => setShowDeleteModal(false)}
            />
            <Dropdown show={showMenu} onClick={() => toggleMenu()}>
                <DropdownToggle><Icon icon={faEllipsisH} /></DropdownToggle>
                <Dropdown.Menu ref={handleClickOutside}>
                    <DropdownItem onClick={handleRowClick}>
                        Öppna
                    </DropdownItem>
                    <DropdownItem onClick={toggleDeleteModal}>
                        Radera
                    </DropdownItem>
                    <DropdownItem onClick={() => copyTextToClipboard(rowData.sickLeaveFormId)}>
                        Kopiera formulär-id ({rowData.sickLeaveFormId})
                    </DropdownItem>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

const DropdownItem = styled(Dropdown.Item)`
    background-color: white;
    color:  ${({ theme }) => theme.colors.primary};
    :hover {
        background-color:  ${({ theme }) => theme.patientList.oddRow.backgroundColor};
        color:  ${({ theme }) => theme.patientList.color};
    }
`;

const DropdownToggle = styled(Dropdown.Toggle)`   
    background: none;
    border: none;
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin: 0 16px;
    padding: 3px;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    
    :not(:disabled):not(.disabled):active,
    :not(:disabled):not(.disabled):active:focus,
    .dropdown-toggle:focus,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle&:focus,
    :focus,
    :hover {
        background: none;
        box-shadow: none;
        border: 1px solid ${({ theme }) => theme.patientList.color};
    }
    :after{
        display: none;
    }
`;

const Icon = styled(FontAwesomeIcon)`
color:  ${({ theme }) => theme.patientList.color};
`;


export default DropDownMenu;
