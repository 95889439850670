import { useReactOidc } from "@axa-fr/react-oidc-context";
import { CareCenterContext, H2, DangerButton, SecondaryButton, useDelete } from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { API_URLS } from "../enums/urls";

export const DeleteModal = ({ rowData, toggleDeleteModal, showDeleteModal, hideDeleteModal }) => {

    const { oidcUser } = useReactOidc();
    const { currentCareCenterId } = useContext(CareCenterContext);
    const { doDelete } = useDelete(API_URLS.PATIENT_DELETE(currentCareCenterId, rowData.patientId), oidcUser.access_token);

    const handleDelete = (e) => {
        e.stopPropagation();
        doDelete().then(() => {
            toggleDeleteModal();
        });
    }

    return (
        <Modal show={showDeleteModal} onHide={hideDeleteModal}>
            <Modal.Header><H2>Radera patient</H2></Modal.Header >
            <Modal.Body>Är du helt säker på att du vill radera följande patient och det ifyllda formuläret?
                <br />
                <br />
                <strong>{rowData.firstName} {rowData.lastName}, {rowData.personId}</strong>
                <br />
                <br />
                <strong>Observera att du inte kan återskapa informationen om du väljer att radera den.</strong></Modal.Body>
            <Modal.Footer>
                <DangerButton
                    onClick={handleDelete}>
                    Ja, radera
                </DangerButton>
                <SecondaryButton
                    onClick={toggleDeleteModal}>
                    Avbryt
                </SecondaryButton>
            </Modal.Footer>
        </Modal>

    );
}
