import React, { useContext, useState } from "react";
import { Modal } from 'react-bootstrap';
import { CareCenterContext, H2, PrimaryButton, SecondaryButton, TextAreaWithLabelAndError, usePut } from "@collabodoc/component-library";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { API_URLS } from "../enums/urls";

const CommentModal = ({ sickLeaveFormId, currentComment, toggleCommentModal, showCommentModal, hideCommentModal, changeComment }) => {
    const { oidcUser } = useReactOidc();
    const { doPut: saveSickLeaveComment } = usePut(API_URLS.SICKLEAVEFORM_SAVECOMMENT, oidcUser.access_token);
    const { currentCareCenterId } = useContext(CareCenterContext);

    const [comment, setComment] = useState(currentComment);

    const updateComment = async (commentText) => {
        saveSickLeaveComment({
            SymptomFormId: parseInt(sickLeaveFormId),
            Comment: comment,
            CareCenterId: currentCareCenterId
        })
            .then(() => changeComment(commentText))
            .then(() => toggleCommentModal());
    };

    return (
        <Modal show={showCommentModal} autoFocus={false} onHide={hideCommentModal}>
            <Modal.Header><H2>Kommentar</H2></Modal.Header>
            <Modal.Body>
                <TextAreaWithLabelAndError
                    autoFocus={true}
                    value={comment}
                    minRows={5}
                    maxRows={10}
                    handleChange={e => setComment(e)}
                    maxLength={128}
                />
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton
                    onClick={toggleCommentModal}>
                    Avbryt
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => updateComment()}>
                    Spara
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );
}
export default CommentModal;