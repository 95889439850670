import React, {useContext} from 'react';
import Clipboard from 'react-clipboard.js';
import { notify } from 'react-notify-toast';
import styled, { ThemeContext } from 'styled-components';
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CopyButton = ({ getText, feedback }) => {

    const theme = useContext(ThemeContext);
    const style = { background: theme.colors.success, text: theme.colors.white };
    const timeout = 2000;

    return (
        <>
            <CopyBtn
                onClick={() => notify.show(feedback, "custom", timeout, style)}
                option-text={getText} >
                <FontAwesomeIcon icon={faCopy} />
                {" Kopiera"}
            </CopyBtn >
        </>
    );
}

const CopyBtn = styled(Clipboard)`
    float: right;
    display: inline;
    background-color: ${({ theme }) => theme.copybutton.backgroundColor};
    border: 1px solid ${({ theme }) => theme.copybutton.borderColor};
    color: white;
    border-radius: 10px 10px 0px 0px;    
    padding: .375rem .75rem;
    font-size: 16px;
    margin-bottom: -2px;
    margin-right: 0;
    :focus {
        outline: none;
    }

`;
export default CopyButton;
