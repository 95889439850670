import React from "react";
import styled from "styled-components";
import PatientTableRow from "./PatientTableRow";

const PatientTable = (filteredPatients) => (
  <Table>
    <thead>
      <tr>
        <th>Hantera</th>
        <th>Personnummer</th>
        <th>Förnamn</th>
        <th>Efternamn</th>
        <th className="d-none d-lg-table-cell">Typ</th>
        <th className="d-none d-lg-table-cell">Längd</th>
        <th className="d-none d-lg-table-cell">Registreringstidpunkt</th>
        <th className="d-none d-lg-table-cell">Kommentar</th>
      </tr>
    </thead>
    <tbody>
      {filteredPatients.patients.map((patient) => (
        <PatientTableRow key={patient.sickLeaveFormId} rowData={patient} />
      ))}
    </tbody>
  </Table>
);

const Table = styled.table`
  width: 100%;
  margin-bottom: 1rem;
`;


export default PatientTable;
