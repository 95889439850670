import { TextAreaWithLabelAndError } from "@collabodoc/component-library";
import React, { useRef } from "react";
import CopyButton from "../CopyButton";
import {
  CardBody,
  CardHeader,
  CardHeaderInfo,
  CardHeaderText,
  CertificateCard,
} from "../StyledComponents/CardStyles";

const SickLeaveFormOtherSickLeaveInfoSection = ({ section, onBlur, handleFormChange }) => {
    const handleTextChange = (value) => {
        handleFormChange({ otherSickLeaveInfo: { ...section, sectionText: value } });
    };

    const textRef = useRef();

    return (
        <>
            <CertificateCard>
                <CardHeader>
                    <CardHeaderText>Övriga upplysningar</CardHeaderText>
                    <CardHeaderInfo>(Kopieras till Webcert)</CardHeaderInfo>
                </CardHeader >
                <CardBody>
                    <CopyButton
                        getText={() => textRef.current.value}
                        feedback={"Övriga upplysningar kopierat"}
                        className="copy-btn btn btn-primary floatRight dispInline"
                    />
                    <TextAreaWithLabelAndError
                        value={section.sectionText}
                        ref={textRef}
                        handleBlur={() => onBlur(section.sectionText, section.certificateColumn)}
                        handleChange={handleTextChange}
                        minRows={2}
                        whiteTextArea={true}
                    />
                </CardBody>
            </CertificateCard>
            <br />
        </>
    );
}

export default SickLeaveFormOtherSickLeaveInfoSection;



