import { P, TextAreaWithLabelAndError } from "@collabodoc/component-library";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { isNullOrWhitespace } from "../../utils/helpers";
import CopyButton from "../CopyButton";
import { Asterisk, CardBody } from "../StyledComponents/CardStyles";

const DoubleSection = ({
  patientSection,
  doctorSection,
  sectionTitle,
  doctorSectionTitle,
  onBlur,
  handlePatientChange,
  handleDoctorChange,
  showAllValidationErrors = false,
}) => {
  const [showValidationError, setShowValidationError] = useState(false);


  useEffect(() => {
    if (showAllValidationErrors) {
      setShowValidationError(isNullOrWhitespace(doctorSection.sectionText));
    }
  }, [showAllValidationErrors]);

  const patientTextRef = useRef();
  const doctorTextRef = useRef();

  const textToCopy = () => {
    const patientText = patientTextRef.current.value;
    const doctorText = doctorTextRef.current.value;

    if (patientText && doctorText) {
      return `Patientens uppgifter:\r\n${patientText}\r\n  \r\nMina Observationer och undersökningsresultat:\r\n${doctorText}`;
    } else if (patientText && !doctorText) {
      return `Patientens uppgifter:\r\n${patientText}`;
    } else if (!patientText && doctorText) {
      return `Mina Observationer och undersökningsresultat:\r\n${doctorText}`;
    }
  };

  return (
    <CardBody>
      <Row>
        <Col>
          <Label>
            <Asterisk>*</Asterisk>
            {sectionTitle}
          </Label>
          <CopyButton getText={textToCopy} feedback={"Kopierat"} />
        </Col>
      </Row>
      <Row>
        <TextSection>
          <SectionHeader>Patientens uppgifter</SectionHeader>
          <TextAreaWithLabelAndError
            value={patientSection.sectionText}
            ref={patientTextRef}
            handleBlur={() =>
              onBlur(
                patientSection.sectionText,
                patientSection.certificateColumn
              )
            }
            handleChange={handlePatientChange}
            id={patientSection.certificateColumn}
            minRows={2}
            whiteTextArea={true}
          />
          <br />
          <br />
          <SectionHeader>{doctorSectionTitle}</SectionHeader>
          <TextAreaWithLabelAndError
            value={doctorSection.sectionText}
            ref={doctorTextRef}
            handleBlur={() => {
              setShowValidationError(isNullOrWhitespace(doctorSection.sectionText));
              onBlur(
                doctorSection.sectionText,
                doctorSection.certificateColumn
              );
            }}
            handleChange={handleDoctorChange}
            required={true}
            id={doctorSection.certificateColumn}
            minRows={2}
            validationError={
              showValidationError ? "Detta fält får inte vara tomt" : ""
            }
          />
        </TextSection>
      </Row>
    </CardBody>
  );
};

const SectionHeader = styled(P)`
  color: ${({ theme }) => theme.sectionHeader.color};
  margin: 0;
`;

const TextSection = styled(Col)`
  background-color: #f1f1f1;
  border: 1px solid #e4e4e4;
  border-top-right-radius: 0;
  padding: 15px;
  margin: 0 15px;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
`;

export default DoubleSection;
