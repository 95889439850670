import React, {useContext} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {CareCenterContext, CareCenterContextProvider} from "@collabodoc/component-library";
import EmployersCertificateForm from "./components/EmployersCertificate/EmployersCertificateForm";
import NavMenu from "./components/NavMenu";
import {CareCenterRequirement} from "./CareCenterRequirement";
import PatientList from "./components/PatientList";
import SickLeaveForm from "./components/SickLeaveForm/SickLeaveForm";
import {API_URLS, SITE_URLS} from "./enums/urls";
import styled, {ThemeProvider} from "styled-components";
import ChooseCareCenter from "./ChooseCareCenter";
import {oidcLog, withOidcUser} from '@axa-fr/react-oidc-context';
import {useClearCacheCtx} from "react-clear-cache";
import {Container} from "react-bootstrap";
import Notifications from 'react-notify-toast';
import {FontProvider} from "./Content/fonts";
import {getTheme} from "./Content/themes/getTheme";
import {Auth} from "./Auth";


const withCareCenterContext = WrappedComponent => (props) => {
    const { isLoadingCareCenters, careCenters, setCurrentCareCenter } = useContext(CareCenterContext);
    return (<WrappedComponent {...props}
        careCenters={careCenters}
        isLoadingCareCenters={isLoadingCareCenters}
        setCurrentCareCenter={setCurrentCareCenter}
    >
        {props.children}</WrappedComponent>)
}

const App = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    if (!isLatestVersion) {
        emptyCacheStorage()
            .then(() => {
                window.location.reload();
            });
    }

    const baseUrl = window.location.protocol + "//" + window.location.host;

    const config = {
        isEnabled: process.env.NODE_ENV !== "test",
        client_id: window.REACT_APP_CLIENT_ID,
        response_type: 'code',
        post_logout_redirect_uri: `${baseUrl}${SITE_URLS.AUTH_SIGNED_OUT}`,
        redirect_uri: `${baseUrl}${SITE_URLS.AUTH_SIGNED_IN}`,
        silent_redirect_uri: `${baseUrl}${SITE_URLS.AUTH_SIGNED_IN_SILENT}`,
        scope: 'openid profile role sickleavefrontend custom carecenter_api',
        authority: `${window.REACT_APP_USER_SERVICE_URL}/identity`,
        automaticSilentRenew: true,
        loadUserInfo: true,
        triggerAuthFlow: true,
    };

    const theme = getTheme();

    return (
        <ThemeProvider theme={theme}>
            <FontProvider />
            <Notifications options={{ top: '56px' }} />
            <Auth config={config} loggerLevel={oidcLog.WARN} >
                <CareCenterContextProviderWithUser apiUrl={API_URLS.CARECENTERS} siteName={window.REACT_APP_CAREGIVER_SITENAME}>
                    <NavMenu />
                    <ViewContainer fluid>
                        <Switch>
                            <Route path={SITE_URLS.CHOOSE_CARECENTER} component={withCareCenterContext(ChooseCareCenter)} />
                            <Route>
                                <CareCenterRequirement>
                                    <Route path={SITE_URLS.SICKLEAVEFORM} component={SickLeaveForm} />
                                    <Route
                                        path={SITE_URLS.EMPLOYERSCERTIFICATEFORM}
                                        component={EmployersCertificateForm}
                                    />
                                    <Route path={SITE_URLS.PATIENTLIST} component={PatientList} />
                                    <Redirect exact from="/" to={SITE_URLS.PATIENTLIST} />
                                </CareCenterRequirement>
                            </Route>
                        </Switch>
                    </ViewContainer>
                </CareCenterContextProviderWithUser>
            </Auth>
        </ThemeProvider>
    );
};

const CareCenterContextProviderWithUser = withOidcUser(CareCenterContextProvider)

const ViewContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: 20px;
`;

export default App;
