import { CopyButtonSmall, P } from "@collabodoc/component-library";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  CardBody,
  CardHeader,
  CardHeaderText,
  CertificateCard,
} from "./StyledComponents/CardStyles";

const ContactPersonCard = ({ sickLeaveForm }) => {
  const ssnRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const tenNumberSSN = sickLeaveForm.personId;
  const phone = sickLeaveForm.patientPhoneNumber;

  return (
    <>
      <CertificateCard>
        <CardHeader>
          <CardHeaderText>Om patienten</CardHeaderText>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12}>
              <ContactPersonTitle>Namn</ContactPersonTitle>
              <span>{sickLeaveForm.patientName}</span>
              <ContactPersonTitle>Personnummer</ContactPersonTitle>
              <span>{sickLeaveForm.personId}</span>
              <span hidden ref={ssnRef}>
                {tenNumberSSN}
              </span>
              &nbsp;
              <CopyButtonSmall
                feedback={"Personnummer kopierat"}
                textValue={tenNumberSSN}
              />
              {phone && (
                <>
                  <ContactPersonTitle>Telefonnummer</ContactPersonTitle>
                  <span>{sickLeaveForm.patientPhoneNumber}</span>
                  <span hidden ref={phoneNumberRef}>
                    {phone}
                  </span>
                  &nbsp;
                  <CopyButtonSmall
                    feedback={"Telefonnummer kopierat"}
                    textValue={phone}
                  />
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </CertificateCard>
      <br />
    </>
  );
};

const ContactPersonTitle = styled(P)`
  font-weight: bold;
  margin: 0;
`;

export default ContactPersonCard;
