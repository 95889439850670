import { TextAreaWithLabelAndError } from "@collabodoc/component-library";
import React, { useRef } from "react";
import styled from "styled-components";
import CopyButton from "../CopyButton";
import {
  Asterisk,
  CardBody,
  CardHeader,
  CardHeaderInfo,
  CardHeaderText,
  CertificateCard,
} from "../StyledComponents/CardStyles";

const SickLeaveFormOccupationSection = ({
  onBlur,
  section,
  occupation,
  handleFormChange,
}) => {
  const isChecked = (checkboxId) => {
    if (checkboxId === occupation) {
      return true;
    }
  };

  const textRef = useRef();

  const handleTextChange = (value) => {
    handleFormChange({ workInfo: { ...section, sectionText: value } });
  };

  return (
    <>
      <CertificateCard>
        <CardHeader>
          <CardHeaderText>Sysselsättning</CardHeaderText>
          <CardHeaderInfo>(Kopieras till Webcert)</CardHeaderInfo>
        </CardHeader>
        <CardBody>
          <Label>
            <Asterisk>*</Asterisk>I relation till vilken sysselsättning bedömer
            du arbetsförmågan?
          </Label>
          <br />
          <CheckBox defaultChecked={isChecked("Employed")} />
          Nuvarande arbete
          <br />
          <CheckBox defaultChecked={isChecked("Unemployed")} />
          Arbetssökande - att utföra sådant arbete som är normalt förekommande
          på arbetsmarknaden
          <br />
          <CheckBox defaultChecked={isChecked("ParentalLeave")} />
          Föräldraledighet för vård av barn
          <br />
          <CheckBox defaultChecked={isChecked("Student")} />
          Studerande
          <br />
          <LabelWithMargin>
            <Asterisk>*</Asterisk>Ange yrke och arbetsuppgifter
          </LabelWithMargin>
          <CopyButton
            getText={() => textRef.current.value}
            feedback={"Sysselsättning kopierat"}
          />
          <TextAreaWithLabelAndError
            whiteTextArea={true}
            ref={textRef}
            id={section.certificateColumn}
            minRows={2}
            value={section.sectionText}
            handleBlur={() =>
              onBlur(section.sectionText, section.certificateColumn)
            }
            handleChange={handleTextChange}
          />
        </CardBody>
      </CertificateCard>
      <br />
    </>
  );
};

const CheckBox = styled.input.attrs({
  type: "checkbox",
  disabled: true,
})`
  margin-right: 5px;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
`;

const LabelWithMargin = styled(Label)`
  margin-top: 10px;
`;

export default SickLeaveFormOccupationSection;
